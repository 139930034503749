<template>
  <Card>
    <template #header>
      <div
        class="d-flex align-items-center justify-content-between"
        style="width: 100%"
      >
        <div>
          <h4>İlanlar</h4>
        </div>
        <div>
          <router-link :to="`sales/detail/1`">
            <button
              style="
                font-size: 14px;
                background-color: rgba(0, 0, 0, 0.08);
                color: #383838;
              "
              class="p-4 rounded-sm"
            >
              <i class="flaticon2-plus mr-2"></i>Yeni Devir Kaydı
            </button>
          </router-link>
        </div>
      </div>
    </template>

    <div class="custom-list">
      <List
        :header="header"
        :data="items"
        :pagination="pagination"
        :loading="loading"
        @updatePage="updatePage"
        moduleName="Sistem Kullanıcı Listesi"
        :moduleCreateEvent="true"
        :moduleSearch="search"
      >
        <template v-slot:item.varlik="{ item }">
          <div class="badges-area">
            <div class="badges">
              <i
                class="badge"
                v-for="(badges, key) in item.badges"
                :key="key"
                :class="[badgesEnum[key]]"
              >
              </i>
            </div>
            <p>{{ item.varlik }}</p>
          </div>
        </template>

        <template v-slot:item.activeStatus="{ item }">
          <Steps :steps="4" :activeStep="item.activeStatus" />
        </template>
        <template v-slot:item.tapu="{ item }">
          <div class="tapu-text">
            <h6>{{ item.tapu }}</h6>
            <span
              >tapu: <strong>{{ item.tapuCount }}</strong> | Taşınmaz:
              <strong>{{ item.tasinmazCount }}</strong>
            </span>
          </div>
        </template>
        <template v-slot:item.action="{ item }" class="p-0">
          <router-link
          :to="`sales/detail/1`"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
          
            <b-button
              variant="primary"
              class="ml-auto"
              :href="href"
              @click="navigate"
              >Detay</b-button
            >
          </router-link>
        </template>
      </List>
      
      <!-- Create and Actions Popups -->
    </div>
  </Card>
</template>

<style lang="scss" scoped>
@media (max-width: 990px) {
  .search-list-area {
    flex-direction: column;
    .search-input {
      width: 100%;
      * {
        width: 100%;
      }
    }
  }
  .search-buttons {
    flex-direction: column;
  }
}

.badges-area {
  display: flex;
  gap: 1rem;
  p {
    font-size: 14px !important;
    font-weight: 600 !important;
    color: $dveb-primary !important;
  }
  .badges {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    .badge {
      display: inline-block;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      padding: 0 !important;

      &.green {
        background-color: #44b6ae;
      }
      &.yellow {
        background-color: #ffac00;
      }
      &.red {
        background-color: red;
      }
    }
  }
}
.tapu-text {
  span {
    background-color: $dveb-divider;
    padding: 0.2rem 0.5rem;
    border-radius: 0.3rem;
  }
}
</style>

<script>
export default {
  name: "customerList",
  components: {
    Steps: () => import("@/components/global/StepBar"),
  },
  data() {
    return {
      search: "",
      filters: "",
      showInsertUpdate: false,
      showDetail: false,
      showImportModal: false,
      resetForm: false,
      confirmCreate: false,
      loading: false,
      badgesEnum: ["green", "yellow", "red"],
      header: [
        { text: "Varlık", value: "varlik" },
        { text: "Banka Gayrimenkul Kodu", value: "kod" },
        { text: "İlan Bilgileri", value: "tapu" },
        { text: "Durum", value: "activeStatus" },
        { text: "Detay", value: "action", size: "120px" },
      ],
      items: [],
      pagination: {},
      meta: {},
      listSelectedItemIds: [],
      selectedItem: "",
      deleteOperationUrl: "",
      deleteIds: "",
    };
  },

  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      this.items = [
        {
          id: 1,
          varlik: "DENEME SEKERBANK DEViR",
          kod: "Banka Gayrimenkul Kodu",
          tapu: "Hatay ANTAKYA",
          tapuCount: 1,
          tasinmazCount: 0,
          activeStatus: 2,
          badges: [1, 2, 3],
          islem: "1",
        },
        {
          id: 2,
          varlik: "DENEME SEKERBANK DEViR",
          kod: "Banka Gayrimenkul Kodu",
          tapu: "Hatay ANTAKYA",
          tapuCount: 1,
          tasinmazCount: 0,
          activeStatus: 2,
          badges: [1, 2],
          islem: "1",
        },
        {
          id: 3,
          varlik: "DENEME SEKERBANK DEViR",
          kod: "Banka Gayrimenkul Kodu",
          tapu: "Hatay ANTAKYA",
          tapuCount: 1,
          tasinmazCount: 0,
          activeStatus: 3,
          badges: [1, 2],
          islem: "1",
        },
        {
          id: 4,
          varlik: "DENEME SEKERBANK DEViR",
          kod: "Banka Gayrimenkul Kodu",
          tapu: "Hatay ANTAKYA",
          tapuCount: 1,
          tasinmazCount: 0,
          activeStatus: 4,
          badges: [1, 2],
          islem: "1",
        },
      ];
      this.totalItems = 3;

      this.pagination = {
        current_page: 1,
        total_pages: 1,
        total_items: 3,
      };
      this.loading = false;
      if (this.search) {
        const searchValue = this.search;
        const foundItems = this.items.filter((item) => {
          for (const prop in item) {
            if (item[prop].toString().toLowerCase().includes(searchValue)) {
              return true;
            }
          }
          return false;
        });
        this.items = foundItems;
      }
    },
    updatePage(value) {
      this.meta = value;

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  watch: {
    meta: {
      handler() {
        this.getList();
      },
      deep: true,
    },
    search: function (params) {
      this.getList();
    },
  },
};
</script>
